import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

const DayOfWeekTotal = ({ barOptions, dayOfWeekTotals}) => {

  const [barData, setBarData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Accumulated Gain$',
        data: [],
        backgroundColor: [],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    updateDataHandler();
  }, [dayOfWeekTotals]);

  const updateDataHandler = () => {
    const labels = Object.keys(dayOfWeekTotals);
    const data = Object.values(dayOfWeekTotals);
    const colours = data.map((value) => value < 0 ? 'rgba(233, 133, 93, 0.719)' : 'rgba(75,192,192,0.4)');

    setBarData({
      labels,
      datasets: [
        {
          ...barData.datasets[0], 
          data,
          backgroundColor: colours
        },
      ],
    });
  };

  return (
    <div>
      <Bar 
        data = {barData}
        options = {barOptions}
        height={400}
      />
    </div>
  )
};

export default DayOfWeekTotal;